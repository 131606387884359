import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <ul className="icons">
      <li>
        <a
          href="https://www.facebook.com/MarkFordPeakallianceRealty"
          className="icon fa-facebook"
        >
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a
          href="https://instagram.com/mark_ford_realtor"
          className="icon fa-instagram"
        >
          <span className="label">Instagram</span>
        </a>
      </li>
    </ul>

    {/*   {
      <p className="copyright">
        &copy; Gatsby Starter - Dimension. Design:{' '}
        <a href="https://html5up.net">HTML5 UP</a>. Built with:{' '}
        <a href="https://www.gatsbyjs.org/">Gatsby.js</a>
      </p>
    } */}
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
