import PropTypes from 'prop-types'
import React from 'react'
import emailjs from 'emailjs-com'
import { StaticImage } from 'gatsby-plugin-image'

// import riverfront from '../images/riverfront.webp'
// import streetview from '../images/streetview.webp'
// import bridgePic from '../images/bridge.webp'
// import interiorPic from '../images/interior.webp'
// import porchPic from '../images/porch.webp'

const PorchPic = () => (
  <StaticImage src="../images/porch.webp" alt="Front porch with swing" />
)
const InteriorPic = () => (
  <StaticImage
    src="../images/interior.webp"
    alt="Interior, livingroom looking into kitchen"
  />
)
const BridgePic = () => (
  <StaticImage
    src="../images/bridge.webp"
    alt="Rail bridge and dam of Paris Ontario"
  />
)
const StreetViewPic = () => (
  <StaticImage
    src="../images/streetview.webp"
    alt="Paris Ontario, downtown streetview"
  />
)
const RiverfrontPic = () => (
  <StaticImage
    src="../images/riverfront.webp"
    alt="Riverside view of Paris downtown (Grand River)"
    layout="fullWidth"
  />
)

class Main extends React.Component {
  state = {
    showForm: true,
    emailError: false,
  }

  toggleForm = override => {
    this.setState({
      showForm: override || !this.state.showForm,
    })
  }

  setEmailError = newState => {
    this.setState({
      emailError: newState,
    })
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    const checkEmailJSSettings = () => {
      if (
        process.env.GATSBY_EMAILJS_SVC_ID &&
        process.env.GATSBY_EMAILJS_TEMPLATE_ID &&
        process.env.GATSBY_EMAILJS_UID
      ) {
        return true
      }
      return false
    }

    const validateFormFields = inputFields => {
      /* No Empty Fields */
      if (inputFields.every(x => x)) return true
      return false
    }
    const handleSubmitForm = event => {
      event.preventDefault()
      const name = event.target[0].value
      const email = event.target[1].value
      const message = event.target[2].value
      if (!validateFormFields([name, email, message])) {
        return //Exit due to Failed Validation
        //Add a toaster saying please fill out all fields
      }
      emailjs
        .sendForm(
          process.env.GATSBY_EMAILJS_SVC_ID,
          process.env.GATSBY_EMAILJS_TEMPLATE_ID,
          event.target,
          process.env.GATSBY_EMAILJS_UID
        )
        .then(
          result => {
            if (result.status === 200) this.toggleForm(false)
          },
          error => {
            console.log(`AAerror`, error)
            this.toggleForm(false)
            this.setEmailError(true)
            // console.log(error.text)
          }
        )
    }

    const ContactForm = (
      <form onSubmit={handleSubmitForm}>
        {/* <form> */}
        <div className="field half first">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" />
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="4"></textarea>
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="Send Message" className="special" />
          </li>
          <li>
            <input type="reset" value="Reset" />
          </li>
        </ul>
      </form>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Buying a home</h2>

          <p>
            Near Brantford and commuting distance to Kitchener-Waterloo, Paris,
            and surrounding Brant county are fantastic communities to put down
            roots. From the quaint downtown core featuring award-winning
            restaurants to spacious rural properties to get away from it all,
            there is something for everyone in Brant.
          </p>
          <span className="image main">
            <StreetViewPic />
            {/* <img
              src={streetview}
              alt="Paris Ontario Downtown core, street level"
            /> */}
          </span>
          {/*   <span className="image main">
            <img src={pic04} alt="Overlook of Paris Ontario downtown" />
          </span> */}
          {/*  <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
            dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Etiam tristique
            libero eu nibh porttitor fermentum. Nullam venenatis erat id
            vehicula viverra. Nunc ultrices eros ut ultricies condimentum.
            Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae
            dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in
            lectus. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. In non lorem sit amet elit
            placerat maximus. Pellentesque aliquam maximus risus, vel sed
            vehicula.
          </p> */}
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Selling your home</h2>
          <span className="image main">
            <InteriorPic />
            {/* <img src={interiorPic} alt="Home interior" /> */}
          </span>
          <p>
            Wondering what your home is worth? With a thorough knowledge of real
            estate in the area, I can appraise your home based on the local
            market. It's never been a better time to sell in Brant County.
          </p>
          <span className="image main">
            <PorchPic />
          </span>
          {/*  <p>
            Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
            libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
            Pellentesque condimentum sem. In efficitur ligula tate urna.
            Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor.
            Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
            libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat
            tempus.
          </p> */}
          {/*   <span className="image main">
            <img src={pic04} alt="" />
          </span> */}
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>

          <p>
            Hello, my name is Mark Ford, and I am a long-time Paris resident.
            Growing up in Paris, I came to love the community/region and its
            diverse selection of amenities from our rivers, walking paths,
            sports, community groups, churches, and schools.
          </p>
          <span className="image main">
            <BridgePic />
            {/* <img src={bridgePic} alt="Rail bridge in Paris Ontario" /> */}
          </span>

          <p>
            It would be my pleasure to assist you in finding that family home or
            condo that meets your needs. I have many years of experience as a
            labour negotiator, a career in which I have built the framework that
            allows me to develop strong working relationships with my clients.
          </p>
          <p>
            My focus is solely on providing excellent service while making the
            process as stress-free as possible; whether you are buying, selling,
            or just looking. It would be my honor to assist you in finding that
            dream location.
          </p>
          <span className="image main ">
            <RiverfrontPic />
            {/* <img
              style={{ aspectRatio: '7/3' }}
              src={riverfront}
              alt="Paris Ontario view over the Grand River"
            /> */}
          </span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>

          {checkEmailJSSettings() && this.state.showForm && ContactForm}
          {!this.state.showForm && !this.state.emailError && (
            <span>
              <h3>Message Sent</h3>
              <p>
                Thank you for reaching out, we will get back to you as soon as
                we can.
              </p>
            </span>
          )}

          {this.state.emailError && (
            <span>
              <h3>Uh oh,</h3>
              <p>
                There was an issue while sending your message, please refresh
                the page and try again, or feel free to reach out using the
                contact information below.
              </p>
            </span>
          )}

          <span className="contactInfo">
            <a href="tel:5197612183">Direct Line: 519.761.2183</a>
            {/* <br /> */}
            <a href="tel:5194423100">Office: 519.442.3100</a>
            {/* <br /> */}
            <a href="emailto:markford@peakalliance.ca">
              Email: markford@peakalliance.ca
            </a>
          </span>
          <br />

          <ul className="icons" style={{ paddingTop: '0.5rem' }}>
            <li>
              <a
                href="https://www.facebook.com/MarkFordPeakallianceRealty"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/mark_ford_realtor"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
